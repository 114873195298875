export function* range(endExclusive: number): Generator<number, void> {
    for (let i = 0; i < endExclusive; i++) {
        yield i;
    }
}

export function newFilledArray<T>(arrayLength: number, filler: (index: number) => T): T[] {
    return Array.from(range(arrayLength)).map(filler);
}

export function mod(m: number, n: number): number {
    return ((m % n) + n) % n;
}

export function _throw(errorMessage: string): never {
    throw new Error(errorMessage);
}

export function randomNumber(maxExclusive: number): number {
    return Math.floor(Math.random() * maxExclusive);
}

export function randomBetween(minInclusive: number, maxInclusive: number): number {
    const rangeSize = maxInclusive - minInclusive + 1;
    return randomNumber(rangeSize) + minInclusive;
}

export function selectRandom<T>(array: readonly T[]): T {
    return array[randomNumber(array.length)];
}

export function clone<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj));
}
