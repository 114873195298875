import p5 from 'p5';
import createColormap from 'colormap';

import { newFilledArray, range, mod, _throw, randomBetween, randomNumber, selectRandom, clone } from './utils';

const COLORMAP_NAMES = Object.freeze([
    'bone',
    'cdom',
    'chlorophyll',
    'cool',
    'bathymetry',
    'blackbody',
    'density',
    'freesurface-red',
    'greys',
    'inferno',
    'jet',
    'magma',
    'picnic',
    'portland',
    'rainbow-soft',
    'rdbu',
    'salinity',
    'summer',
    'temperature',
    'velocity-blue',
    'viridis',
    'warm',
    'yignbu',
    'yiorrd',
]);

const RULE_TO_TURN = Object.freeze({
    L: left,
    R: right,
    N: noChange,
});

const AVAILABLE_RULES = Object.freeze(Object.keys(RULE_TO_TURN).sort());

const CANVAS_SIZE = 800;

const POTENTIAL_CELL_SIZES = [0.01, 0.005].map(percentage => CANVAS_SIZE * percentage);
POTENTIAL_CELL_SIZES.forEach(size => console.assert(Number.isInteger(size), `Cell size is not an integer: ${size}`));

interface Position {
    row: number;
    col: number;
}

/** The values which are determined before a simulation begins and are readonly during the simulation */
type Conditions = Readonly<{
    antCount: number;
    initialAntPositions: Position[];
    cellsPerSide: number;
    cellSize: number;
    rule: string;
    incrementColorIndex: (colorIndex: number) => number;
    turns: Array<(direction: number) => number>;
    backgroundColor: p5.Color;
    antColor: p5.Color;
    getColor: (colorIndex: number) => p5.Color;
    stepsPerFrame: number;
}>;

type ColorIndex = number & { __type?: 'ColorIndex' };

type Ant = Position & { direction: number };

type Grid = ColorIndex[][];

/** The values which are modified during a simulation (excepting `conditions`) */
interface State {
    conditions: Conditions;
    ants: Ant[];
    grid: Grid;

    /** Uses -1 to represent and invalidate the color of a cell occupied by an ant */
    previousRender: Grid;
}

function generateConditions(colorConverter: (hexColor: string) => p5.Color): Conditions {
    const cellSize = selectRandom(POTENTIAL_CELL_SIZES);
    const cellsPerSide = CANVAS_SIZE / cellSize;

    const antCount = randomBetween(1, 4);

    const randomCell = () => randomNumber(cellsPerSide);
    const initialAntPositions = newFilledArray(antCount, () => ({
        row: randomCell(),
        col: randomCell(),
    }));

    const rule = (function () {
        function generateRule(): string[] {
            const ruleLength = randomBetween(2, 8);
            return newFilledArray(ruleLength, () => selectRandom(AVAILABLE_RULES));
        }

        function isRuleValid(potentialRule: string[]): boolean {
            return potentialRule.some(c => c === 'L') && potentialRule.some(c => c === 'R');
        }

        let potentialRule;
        do {
            potentialRule = generateRule();
        } while (!isRuleValid(potentialRule));

        return potentialRule;
    })();

    // `createColormap()` requires values for `nshades` larger than
    // the number of rules we often have, so multiply the number of colors
    // by a fixed amount to work around that problem.
    const COLOR_MAP_FACTOR = 10;

    const colorMap = createColormap({
        nshades: rule.length * COLOR_MAP_FACTOR,
        colormap: selectRandom(COLORMAP_NAMES),
        format: 'hex',
    }).map(colorConverter);

    const backgroundColor = colorMap[0];
    const antColor = colorMap[colorMap.length - 1];
    const getColor = (colorIndex: number) => colorMap[colorIndex * COLOR_MAP_FACTOR];

    const { incrementColorIndex, turns } = parseRule(rule);

    const stepsPerFrame = randomBetween(1, 40);

    return Object.freeze({
        antCount,
        initialAntPositions,
        cellsPerSide,
        cellSize,
        rule: rule.join(''),
        incrementColorIndex,
        turns,
        backgroundColor,
        antColor,
        getColor,
        stepsPerFrame,
    });
}

function generateState(conditions: Conditions): State {
    const { initialAntPositions, cellsPerSide } = conditions;

    const ants = initialAntPositions.map(pos => ({ ...pos, direction: 0 }));

    const grid: number[][] = newFilledArray(cellsPerSide, () => newFilledArray(cellsPerSide, () => 0));

    return { conditions, ants, grid, previousRender: clone(grid) };
}

const el = (id: string) => document.getElementById(id)!;

new p5((_: p5) => {
    let conditions: Conditions;
    let state: State;

    const reset = () => {
        conditions = generateConditions(hexColor => _.color(hexColor));
        state = generateState(conditions);

        el('rule').textContent = conditions.rule;

        _.background(conditions.backgroundColor);
    };

    function drawRect({ row, col }: Position): void {
        const { cellSize } = conditions;
        _.rect(col * cellSize, row * cellSize, cellSize, cellSize);
    }

    function move(ant: Ant): void {
        const { cellsPerSide } = conditions;

        const rowDelta = -Math.round(Math.sin(ant.direction));
        const colDelta = Math.round(Math.cos(ant.direction));

        ant.row = mod(ant.row + rowDelta, cellsPerSide);
        ant.col = mod(ant.col + colDelta, cellsPerSide);
    }

    function step() {
        const { incrementColorIndex, turns } = conditions;
        const { ants, grid } = state;

        for (const ant of ants) {
            const { row, col, direction } = ant;

            const currentColorIndex = grid[row][col];
            grid[row][col] = incrementColorIndex(currentColorIndex);

            ant.direction = turns[currentColorIndex](direction);
            move(ant);
        }
    }

    _.setup = function () {
        const renderer = _.createCanvas(CANVAS_SIZE, CANVAS_SIZE);
        el('canvasContainer').appendChild(el(renderer.id()));

        reset();
        setInterval(reset, 15_000);

        _.strokeWeight(0);
    };

    _.draw = function () {
        const { antColor, getColor, stepsPerFrame } = conditions;
        const { ants, grid, previousRender } = state;

        for (const _ of range(stepsPerFrame)) {
            step();
        }

        for (const [r, row] of grid.entries()) {
            for (const [c, colorIndex] of row.entries()) {
                if (previousRender[r][c] === colorIndex) continue;

                _.fill(getColor(colorIndex));
                drawRect({ row: r, col: c });
            }
        }

        const currentRender = clone(grid);

        _.fill(antColor);
        for (const ant of ants) {
            drawRect(ant);

            currentRender[ant.row][ant.col] = -1;
        }

        state.previousRender = currentRender;
    };
});

const QUARTER_CIRCLE = 0.5 * Math.PI;
const FULL_CIRCLE = 2 * Math.PI;

function left(direction: number): number {
    return mod(direction + QUARTER_CIRCLE, FULL_CIRCLE);
}

function right(direction: number): number {
    return mod(direction - QUARTER_CIRCLE, FULL_CIRCLE);
}

function noChange(direction: number): number {
    return direction;
}

function parseRule(rule: string[]) {
    const colorCount = rule.length;
    function incrementColorIndex(color: number): number {
        return mod(color + 1, colorCount);
    }

    const turns = rule.map(c => RULE_TO_TURN[c as keyof typeof RULE_TO_TURN] ?? _throw(`Unknown rule ${c}`));

    return { incrementColorIndex, turns };
}
